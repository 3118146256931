<template>
  <span v-if="moeda_sigla == 'G$'">
    <span>{{ moeda_sigla }} </span>
    <span>{{ valor | guarani }}</span>
  </span>
  <span v-else>
    <span>{{ moeda_sigla }} </span>
    <span>{{ valor | currency }}</span>
  </span>
</template>

<script>
export default {
  data() {
    return {
      currency: {
        decimal: ",",
        thousands: ".",
        precision: 2,
        masked: false,
      },
    };
  },
  props: {
    valor: {
      type: [Number, String],
      default: 0,
    },
    moeda_sigla: {
      type: [String, Number],
      default: "R$",
    },
  },
};
</script>

<style></style>
